import React from 'react';
import { ListItem, ListItemText, Button } from '@material-ui/core';

import { styles } from '../../styles/componentStyles/ColorLegendStyles';

const ColorLegendElement = ({ colorLabel, legendColor, index }) => {
  const classes = styles();

  return (
    <ListItem
      dense
      style={{
        height: '20px',
        paddingLeft: '0px',
        paddingRight: '0px',
      }}
    >
      <ListItemText
        primary={colorLabel}
        style={{
          fontSize: '6',
        }}
      />
      {index !== 0 && (
        <Button
          variant='outlined'
          disableElevation
          className={classes.colorButton}
          style={{ backgroundColor: legendColor }}
        ></Button>
      )}
    </ListItem>
  );
};

export default ColorLegendElement;
