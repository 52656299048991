import React, { useEffect, useState, useReducer } from 'react';
import { List } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { styles } from '../../styles/componentStyles/ColorLegendStyles';
import { BlackAndWhiteTooltip } from './BlackAndWhiteTooltip';
import { emptyLegendData, legendDataFromDataType } from './ColorLegendUtil';
import ColorLegendElement from './ColorLegendElement';

const legendDataReducer = (state, action) => {
  return action.payload;
};

const ColorLegend = ({ nbrSearched, dataType }) => {
  const classes = styles();

  const [nbrSearchedLocal, setNbrSearchedLocal] = useState(0);
  const [legendData, legendDataDispatch] = useReducer(
    legendDataReducer,
    emptyLegendData
  );

  useEffect(() => {
    if (nbrSearched !== nbrSearchedLocal) {
      const newLegendData = legendDataFromDataType(dataType);
      legendDataDispatch({ payload: newLegendData });
      setNbrSearchedLocal(nbrSearched);
    }
  }, [nbrSearched, dataType, nbrSearchedLocal]);

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-end'
      alignItems='flex-start'
      className={classes.grid}
    >
      <Grid item>
        <BlackAndWhiteTooltip
          title={legendData.tooltip}
          placement='right-start'
          arrow
          style={{ margin: '5 px' }}
        >
          <Paper className={classes.paper}>
            <List
              component='nav'
              aria-label='main mailbox folders'
              className={classes.list}
            >
              {legendData.colorScale
                .map((legendColor, index) => [
                  legendColor,
                  legendData.colorLabels[index],
                  index,
                ])
                .map(([legendColor, colorLabel, index]) => (
                  <ColorLegendElement
                    legendColor={legendColor}
                    colorLabel={colorLabel}
                    index={index}
                    key={index}
                  />
                ))}
            </List>
          </Paper>
        </BlackAndWhiteTooltip>
      </Grid>
    </Grid>
  );
};

export default ColorLegend;
