import React from 'react';
import {Card, Grid, Typography} from '@material-ui/core';

import AppBar from '../components/landing/AppBar';
import {samstatTheme} from '../themes/SamstatTheme';

const textXs = 12;
const textSm = 12;
const textMd = 12;
const textLg = 8;
const textXl = 8;

const InfoView = () => {
    return (
        <div>
            <AppBar/>
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                style={{minHeight: '100vh'}}
            >
                <Grid item xs={textXs} sm={textSm} md={textMd} lg={textLg} xl={textXl}>
                    <Card
                        style={{
                            overflow: 'auto',
                            zIndex: 9001,
                            position: 'relative',
                            margin: '10px',
                            borderRadius: 0,
                            boxShadow: 'none',
                        }}
                    >
                        <Typography
                            variant='h3'
                            style={{color: samstatTheme.palette.primary.main}}
                        >
                            Samstat
                        </Typography>
                        <Typography
                            style={{marginBottom: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
                        >
                            Samstat är ett verktyg för visualisering av svensk
                            samhällsstatistik. Sverige tillhandahåller en mängd öppna dataset
                            och APIer, men för att ta del av informationen krävs ofta
                            expertkunskap och specialiserade verktyg. Vi ser det som vårat
                            uppdrag att göra den svenska offentliga datan tillgänglig till
                            gemene man.
                        </Typography>
                        <br/>
                        <Typography
                            variant='h4'
                            style={{color: samstatTheme.palette.primary.main}}
                        >
                            Beteende och optimering
                        </Typography>
                        <Typography
                            style={{
                                marginBottom: '10px',
                                color: 'rgba(0, 0, 0, 0.54)',
                            }}
                        >
                            Med hjälp av Samstat kan användare på ett enkelt sätt visualisera
                            geospatiell data över Sveriges invånare och institutioner. Mängden
                            data som krävs för att visualisera hela riket samtidigt är stor.
                            För att ge våra användare en så bra upplevelse som möjligt har vi
                            valt att dela upp datan på regionsbasis. När en användare söker på
                            ett område eller address kommer hela den omslutande regionen
                            laddas, och endast den regionen. För att se andra regioner krävs
                            en ny sökning. Detta kan antingen göras genom att skriva in en ny
                            destination i sökrutan och klicka sök eller genom att navigera på
                            kartan till ett nytt område i Sverige och klicka på knappen "ladda
                            aktuellt område".
                        </Typography>
                        <Typography
                            style={{marginBottom: '10px', color: 'rgba(0, 0, 0, 0.54)'}}
                        >
                            För att ytterliggare förbättra användarupplevensen är datan för de
                            geografiska gränserna mellan de olika områdena och de olika
                            datatyperna separerade. Detta innebär att endast den nya data
                            typen behöver hämtas vid en ny sökning i samma region. Därmed är
                            den typen av sökningar signifikant snabbare än sökningar på nya
                            regioner.
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default InfoView;
