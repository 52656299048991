import { samstatTheme } from '../../themes/SamstatTheme';
import { hexToRGB } from '../../util/ColorPaletteUtil';

export const COLOR_SCALE_ELECTION = [
  samstatTheme.palette.primary.dark,
  '#64a81d',
  '#1b5bb2',
  '#3399fe',
  '#66bee6',
  '#fe0000',
  '#f9db07',
  '#c30001',
  '#008002',
];

export const COLOR_SCALE_ELECTION_RGB = hexToRGB(COLOR_SCALE_ELECTION);

export const LABELS_ELECTION = [
  'Störst Parti',
  'C',
  'KD',
  'L',
  'M',
  'S',
  'SD',
  'V',
  'MP',
];

export const TOOLTIP_ELECTION = 'Största partiet i ett område';

export const ELECTION_COLORS = {
  colorScale: COLOR_SCALE_ELECTION,
  colorScaleRgb: COLOR_SCALE_ELECTION_RGB,
  colorLabels: LABELS_ELECTION,
  tooltip: TOOLTIP_ELECTION,
};
