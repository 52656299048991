import { TYPEGROUPS } from '../data/TYPEGROUPS';
import Coordinate from './Coordinate';

class DeckGlConf {
  constructor(
    dataType = '',
    region = '',
    regionData = null,
    contentData = null,
    dataTypeSpecifics = null,
    dataTypeGroup = TYPEGROUPS.SCB,
    coordinate = new Coordinate(61.3774428, 15.8181755, 5)
  ) {
    this.dataType = dataType;
    this.region = region;
    this.regionData = regionData;
    this.contentData = contentData;
    this.dataTypeSpecifics = dataTypeSpecifics;
    this.dataTypeGroup = dataTypeGroup;
    this.coordinate = coordinate;
  }
}

export default DeckGlConf;
