import React from 'react';

import { createDeckGlLayer } from './deckGLUtil';
import { createHighlightedLayer } from './deckGLUtil';
import { logFunction } from './LogUtil';
import { TYPEGROUPS } from '../data/TYPEGROUPS';
import Coordinate from '../structures/Coordinate';

export const onClick = (info, dataTypeGroup, updateMetaData) => {
  logFunction('MapComponent._onClick', info);
  let desoId = '';
  try {
    if (info.object !== undefined) {
      switch (dataTypeGroup) {
        case TYPEGROUPS.SCB:
          desoId = info.object.properties.Deso;
          break;
        case TYPEGROUPS.VAL:
          desoId = info.object.properties.VD;
          break;
        case TYPEGROUPS.SCHOOL:
          desoId = info.object.schoolCode;
          break;
        default:
          throw new Error('error in MapComponent.onClick');
      }
      updateMetaData(info, desoId);
    }
  } catch (error) {
    console.log('Caught error when processing click, error=' + error);
  }
};

/*
export const considerDebounce = (dataType, contentData, regionData, layers) => {
  return (
    dataType !== '' &&
    contentData !== null &&
    regionData !== 'none' &&
    layers.length === 0
  );
};
*/
export const onViewStateChange = (
  interactionState,
  viewState,
  updateCoord,
  mapViewDispatch,
  lowPerformance = false
) => {
  const considerLayerUpdate =
    !interactionState.isDragging &&
    !interactionState.isPanning &&
    !interactionState.isRotating;

  if (lowPerformance)
    mapViewDispatch({
      type: 'update',
      payload: {
        newViewState: {
          isPanning: interactionState.isPanning,
          isDragging: interactionState.isDragging,
          isZooming: interactionState.isZooming,
          isRotating: interactionState.isRotating,
        },
      },
    });
  try {
    const newCoordinate = new Coordinate(
      viewState.latitude,
      viewState.longitude,
      viewState.zoom
    );
    if (considerLayerUpdate) {
      updateCoord(newCoordinate);
    }
  } catch (error) {
    console.log('Received error when updating viewstate, error=' + error);
  }
};

export const initialView = {
  isDragging: false,
  isPanning: false,
  isRotating: false,
  isZooming: false,
};

export const tooltipStyle = {
  position: 'absolute',
  padding: '4px',
  background: 'rgba(0, 0, 0, 0.8)',
  color: '#fff',
  maxWidth: '300px',
  fontSize: '10px',
  zIndex: 9,
  pointerEvents: 'none',
};

export const renderTooltip = (hoverInfo, tooltipStyle) => {
  if (hoverInfo === null) {
    return null;
  }

  if (hoverInfo.object === undefined) {
    return null;
  }

  return (
    <div
      style={{
        ...tooltipStyle,
        left: hoverInfo.x,
        top: hoverInfo.y,
      }}
    >
      <div>{hoverInfo.object.name}</div>
      <div>{`Net gain: ${hoverInfo.object.name}`}</div>
      <div>{`i: ${hoverInfo.object.name}`}</div>
    </div>
  );
};

export const onHover = (info, setHoverInfo) => {
  try {
    setHoverInfo(info);
  } catch (error) {
    console.log('Caught error when processing onHover, error=' + error);
  }
};

export const layersReducer = (state, action) => {
  logFunction('MapComponentUtil.layersReducer', [state, action]);
  switch (action.type) {
    case 'init':
      const return_array = [
        createDeckGlLayer(action.payload.deckGlConf, true),
        createHighlightedLayer(action.payload.highlight_data, 0.2, true),
      ];
      return return_array;
    case 'remove':
      return [];
    case 'hide':
      return [
        createDeckGlLayer(action.payload.deckGlConf, false),
        createHighlightedLayer(action.payload.highlight_data, 0.2, false),
      ];
    default:
      throw new Error('error in layersReducer');
  }
};

export const viewStateReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      // Zooming is when Panning === true and Dragging === false
      if (
        state.isDragging === action.payload.newViewState.isDragging &&
        state.isPanning === action.payload.newViewState.isPanning
      ) {
        return state;
      } else {
        return action.payload.newViewState;
      }
    case 'reset':
      return initialView;
    default:
      throw new Error('error in viewStateReducer');
  }
};
