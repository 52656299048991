import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import { samstatTheme } from '../../themes/SamstatTheme';

const pages = [
  {
    title: 'karta',
    route: '/karta',
  },
  {
    title: 'om',
    route: '/om',
  },
];

const ResponsiveAppBar = () => {
  return (
    <AppBar position='static' sx={{ backgroundColor: 'white' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='/'
            style={{
              mr: 2,
              //   display: { xs: 'none', md: 'flex' },
              //   fontFamily: 'monospace',
              //   fontWeight: 700,
              //   letterSpacing: '.3rem',
              color: samstatTheme.palette.primary.main,
              textDecoration: 'none',
            }}
          >
            SAMSTAT
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.title}
                href={page.route}
                style={{
                  my: 2,
                  color: samstatTheme.palette.primary.main,
                  display: 'block',
                  borderRadius: 0,
                  //   textAlign: 'center',
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
