import { samstatTheme } from '../../themes/SamstatTheme';
import { hexToRGB } from '../../util/ColorPaletteUtil';

export const COLOR_SCALE_INCOME = [
  samstatTheme.palette.primary.dark,
  '#a50f15',
  '#de2d26',
  '#fb6a4a',
  '#fc9272',
  '#fcbba1',
  '#edf8fb', //50%
  '#ccece6',
  '#99d8c9',
  '#66c2a4',
  '#2ca25f',
  '#006d2c',
];

export const COLOR_SCALE_INCOME_RGB = hexToRGB(COLOR_SCALE_INCOME);

export const LABELS_INCOME = [
  'Inkomstnivå',
  '0%',
  '20%',
  '40%',
  '60%',
  '80%',
  'Median',
  '120%',
  '140%',
  '160%',
  '180%',
  '>200%',
];

export const TOOLTIP_INCOME =
  'Relation till genomslittliga medianinkomsten i ett område';

export const INCOME_COLORS = {
  colorScale: COLOR_SCALE_INCOME,
  colorScaleRgb: COLOR_SCALE_INCOME_RGB,
  colorLabels: LABELS_INCOME,
  tooltip: TOOLTIP_INCOME,
};

// export function colorScaleINC(medianIncome) {
//   const rgbColorScale = hexToRGB(COLOR_SCALE_INC).slice(1);
//   let factor = medianIncome / 280200; // RÄKNAT I ÅRINKOMST
//   let i = 0;

//   if (factor < 1) {
//     i = Math.round(factor * 5);
//     return rgbColorScale[i];
//   } else {
//     i = Math.round((factor - 1) * 5) + 5; //Exempel 1.20 -1 = 0.2 -> 0.2 * 5 = 1 -> 1+5 = 6 alltså är det kategori 6 de hamnar i.
//     return rgbColorScale[i];
//   }

//   /*
//   if (i < 0) {
//     if (i <= -1) {
//       return rgbColorScale[0];
//     } else {
//       i = Math.round((i + 1) * 5);

//       return rgbColorScale[i];
//     }
//   } else {
//     if (i >= 1) {
//       return rgbColorScale[rgbColorScale.length - 1];
//     } else {
//       i = Math.round(i * 5) + 5;

//       return rgbColorScale[i];
//     }
//   }
//   */
// }
