import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Fade,
  Typography,
} from '@material-ui/core';

import { samstatTheme } from '../../themes/SamstatTheme';
import { useStyles } from '../../styles/componentStyles/InfoBarStyles';

const InfoBar = (props) => {
  const classes = useStyles();

  return (
    <Fade appear={true} in={true} timeout={4000}>
      <Card className={classes.outerCard}>
        <Card
          style={{
            width: '100%',
            display: 'flex',
            borderRadius: 0,
            boxShadow: 0,
            justifyContent: props.flex,
          }}
        >
          <CardContent>
            {props.title !== undefined && (
              <Typography component="h5" variant="h5">
                {props.title}
              </Typography>
            )}
            <Typography variant="subtitle1" color="textSecondary">
              {props.text}
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/karta"
              sx={{
                color: samstatTheme.palette.primary.main,
              }}
            >
              {props.link}
            </Typography>
            {props.asset !== undefined && (
              <CardMedia
                image={props.asset}
                style={{
                  height: '200px',
                  width: '200px',
                }}
              />
            )}
          </CardContent>
        </Card>
      </Card>
    </Fade>
  );
};

export default InfoBar;
