const flattenSchoolData = (school_data) => {
  let arr = [];
  Object.keys(school_data).forEach((val) => {
    school_data[val].schoolCode = val;
    arr.push(school_data[val]);
  });

  return arr;
};

export default flattenSchoolData;
