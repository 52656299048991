import React, { useEffect, useState } from 'react';

import { sortCategoriesAndLabels, createMetaDataTable } from './MetadataUtil';

const electionLabels = ['C', 'KD', 'L', 'M', 'MP', 'S', 'SD', 'V'];

const ElectionMetadata = ({ desoContent }) => {
  const [categoryLabels, setCategoryLabels] = useState([]);
  const [peopleByCategory, setPeopleByCategory] = useState([]);
  const [elementShouldBold, setElementShouldBold] = useState(0);

  useEffect(() => {
    const electionCategories = [
      desoContent.C,
      desoContent.KD,
      desoContent.L,
      desoContent.M,
      desoContent.MP,
      desoContent.S,
      desoContent.SD,
      desoContent.V,
    ];
    const [categoryLabels, peopleByCategory] = sortCategoriesAndLabels(
      electionCategories,
      electionLabels
    );
    setCategoryLabels(categoryLabels);
    setPeopleByCategory(peopleByCategory);
    const maxIndex = peopleByCategory.indexOf(Math.max(...peopleByCategory));
    const elementShouldBold = new Array(categoryLabels.length)
      .fill(0)
      .map((_, index) => (index === maxIndex ? 1 : 0));
    setElementShouldBold(elementShouldBold);
  }, [desoContent]);

  return (
    <div>
      <span>{desoContent.Valdistriktsnamn}</span>
      {createMetaDataTable(
        ['Riksdagsparti', 'Andel', 'Antal'],
        categoryLabels,
        peopleByCategory,
        desoContent['Antal giltiga valsedlar'],
        elementShouldBold
      )}
      <br />
      <span>{'Källa och aktualitet: SCB 2018.'}</span>
    </div>
  );
};

export default ElectionMetadata;
