import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  outerCard: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    display: 'flex',
    boxShadow: 'none',
    borderRadius: 0,
  },
}));
