import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import { styles } from '../../styles/componentStyles/SearchComponentStyle';

class SearchComponent extends React.Component {
  handleTextChange = (event) => {
    this.props.updateAddress(event.target.value, event.key);
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.searchComponentStyle} variant='outlined'>
        <InputBase
          className={classes.inputBaseStyle}
          onChange={this.handleTextChange}
          onKeyPress={this.handleTextChange}
          value={this.props.userGeneratedAddress}
          placeholder={
            this.props.userGeneratedAddress === ''
              ? 'Område'
              : this.props.userGeneratedAddress
          }
          inputProps={{ 'aria-label': 'search google maps' }}
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon color={'primary'} />
            </InputAdornment>
          }
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(SearchComponent);
