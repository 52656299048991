import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { styles } from '../../styles/componentStyles/SearchButtonStyle';

class SearchButtonComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Button
        variant='contained'
        color='primary'
        className={classes.searchButtonStyle}
        onClick={this.props.handleButtonClick}
      >
        Sök
      </Button>
    );
  }
}

export default withStyles(styles)(SearchButtonComponent);
