import SchoolIcon from '../../assets/school.png';

const LIGHT_SETTINGS = {
  lightsPosition: [-125, 50.5, 5000, -122.8, 48.5, 8000],
  ambientRation: 0.2,
  diffuseRatio: 0.5,
  specularRatio: 0.3,
  lightsStrength: [1.0, 0.0, 2.0, 0.0],
  numberOfLights: 2,
};

export const genGeoJsonLayerSettings = {
  opacity: 0.2,
  stroked: true,
  filled: true,
  extruded: false,
  wireframe: false,
  fp64: true,
  lightSettings: LIGHT_SETTINGS,
  getElevation: 0,
  getLineColor: [0, 0, 0],
  getLineWidth: 1,
  lineWidthScale: 3,
  lineWidthMinPixels: 1,
  pickable: true,
  autoHighlight: true,
};

export const genIconLayerSettings = {
  pickable: true,
  autoHighlight: true,
  highlightColor: [100, 100, 200],
  sizeMaxPixels: 73,
  sizeMinPixels: 56,
  iconAtlas: SchoolIcon,
};
