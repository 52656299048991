import { samstatTheme } from '../../themes/SamstatTheme';
import { hexToRGB } from '../../util/ColorPaletteUtil';

export const COLOR_SCALE_EDUC = [
  samstatTheme.palette.primary.dark,
  '#79111E',
  '#BDD59D',
  '#539193',
];

export const COLOR_SCALE_EDUC_RGB = hexToRGB(COLOR_SCALE_EDUC);

export const LABELS_EDUC = ['Störst kat.', 'Grund.', 'Gymn.', 'Hög.'];

export const TOOLTIP_EDUC = 'Dominerande utbildningsnivå i ett område';

export const EDUCATION_COLORS = {
  colorScale: COLOR_SCALE_EDUC,
  colorScaleRgb: COLOR_SCALE_EDUC_RGB,
  colorLabels: LABELS_EDUC,
  tooltip: TOOLTIP_EDUC,
};
