import React from 'react';
import {
  Paper,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
} from '@material-ui/core';

import { DATATYPES } from '../../data/DATATYPES';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: '100%',
    borderRadius: 0,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  formControlStyle: {
    height: '100%',
    width: '100%',
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  selectStyle: {
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
    height: '40px',
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
  notchedOutline: {},
}));

const SelectBoxComponent = ({ dataType, handleSelectChange }) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  return (
    <form className={classes.paperStyle}>
      <Paper className={classes.paperStyle} elevation={0}>
        <FormControl
          margin='dense'
          variant='outlined'
          className={classes.formControlStyle}
        >
          <InputLabel htmlFor='outlined-age-native-simple'>Datatyp</InputLabel>
          <Select
            native
            className={classes.selectStyle}
            value={dataType}
            onChange={handleSelectChange}
            label='Datatyp'
            input={
              <OutlinedInput
                name='Datatyp'
                label='Datatyp'
                id='outlined-age-simple'
                classes={outlinedInputClasses}
              />
            }
          >
            <option value='' />
            <option value={DATATYPES.age}>Befolkning efter ålder</option>
            <option value={DATATYPES.gender}>Befolkning efter kön</option>
            <option value={DATATYPES.Bakgrund}>
              Befolkning efter bakgrund
            </option>
            <option value={DATATYPES.income}>
              Befolkning efter nettoinkomst
            </option>
            <option value={DATATYPES.work}>
              Befolkning efter sysselsättning
            </option>
            <option value={DATATYPES.educ}>
              Befolkning efter utbildningsnivå
            </option>
            <option value={DATATYPES.val}>
              Valdistrikt och valresultat (2018)
            </option>
            <option value={DATATYPES.school}>Skola och statistik (2019)</option>
          </Select>
        </FormControl>
      </Paper>
    </form>
  );
};

//<option value={'school'}>Skola och statistik (2019)</option>

export default SelectBoxComponent;
