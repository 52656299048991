import React, { useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { styles } from '../../styles/componentStyles/MetaDataStyles';
import { DATATYPES } from '../../data/DATATYPES';
import AgeMetadata from './AgeMetadata';
import GenderMetadata from './GenderMetadata';
import BackgroundMetadata from './BackgroundMetadata';
import IncomeMetadata from './IncomeMetadata';
import WorkMetadata from './WorkMetadata';
import EducationMetadata from './EducationMetadata';
import ElectionMetadata from './ElectionMetadata';
import { logFunction } from '../../util/LogUtil';
import SchoolMetadata from './SchoolMetadata';

const Metadata = ({ metadata, dataType, nbrSearched }) => {
  const classes = styles();
  const [expanded, setExpanded] = useState(true);
  const [content, setContent] = useState(null);
  const [localNbrSearched, setLocalNbrSearched] = useState(0);
  const [localDataType, setLocalDataType] = useState('');

  useEffect(() => {
    const newSearch = nbrSearched > localNbrSearched;
    const activeDataType = newSearch ? dataType : localDataType;
    setContent(getContent(metadata, activeDataType));
    if (newSearch) {
      setLocalNbrSearched(nbrSearched);
      setLocalDataType(dataType);
    }
  }, [metadata, dataType, nbrSearched, localNbrSearched, localDataType]);

  const getContent = (metadata, dataType) => {
    logFunction('Metadata.getContent', [metadata, dataType]);
    switch (dataType) {
      case DATATYPES.age:
        return <AgeMetadata desoContent={metadata} />;
      case DATATYPES.gender:
        return <GenderMetadata desoContent={metadata} />;
      case DATATYPES.Bakgrund:
        return <BackgroundMetadata desoContent={metadata} />;
      case DATATYPES.income:
        return <IncomeMetadata desoContent={metadata} />;
      case DATATYPES.work:
        return <WorkMetadata desoContent={metadata} />;
      case DATATYPES.educ:
        return <EducationMetadata desoContent={metadata} />;
      case DATATYPES.val:
        return <ElectionMetadata desoContent={metadata} />;
      case DATATYPES.school:
        return <SchoolMetadata desoContent={getSchoolData(metadata)} />;
      default:
        return null;
    }
  };

  const getSchoolData = (input) => {
    if (Object.keys(input['gy-statistics']).length > 0) {
      return {
        ...input['gy-statistics'],
        name: input.name,
      };
    } else if (Object.keys(input['gr-statistics']).length > 0) {
      return {
        ...input['gr-statistics'],
        name: input.name,
      };
    } else if (Object.keys(input['fsk-statistics']).length > 0) {
      return {
        ...input['fsk-statistics'],
        name: input.name,
      };
    } else {
      console.log('hittade ingen');
    }
  };

  return (
    <Accordion
      onChange={() => setExpanded(!expanded)}
      expanded={expanded}
      className={classes.metaDataAccordionStyle}
      spacing={0}
      square={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className={classes.metaDataAccordionSummaryStyle}
      >
        <span>Detaljer</span>
      </AccordionSummary>
      <AccordionDetails className={classes.metaDataAccordionDetailsStyle}>
        <Paper className={classes.metaDataPaperStyle} square={true}>
          <div style={{ padding: '5px', margin: '5px', width: '100%' }}>
            {content}
          </div>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default Metadata;
