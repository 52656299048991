import { api_url_db } from '../data/ApiURL';
import { regionIDs } from '../data/Regions';
import { logFunction } from './LogUtil';
import Geobuf from 'geobuf';
import Pbf from 'pbf';
import { DATATYPES } from '../data/DATATYPES';
import ky from 'ky';

export const fetchRegion = async (region, dataTypeGroup) => {
  logFunction('DbIO.fetchRegion', [region, dataTypeGroup]);

  if (region === 'none' || dataTypeGroup === '') {
    throw new Error("'one of region, dataType or format_type is None'");
  }

  const params = new URLSearchParams([
    ['dataTypeGroup', dataTypeGroup],
    ['regionID', regionIDs[region]],
  ]);

  let response = await ky.get(api_url_db + '/borders', {
    searchParams: params,
  });

  if (dataTypeGroup === DATATYPES.school) {
    return await response.json();
  } else {
    console.log('regions ---');
    const preSignedUrl = await response.json();
    const dataResponse = await ky.get(preSignedUrl);
    const data = await dataResponse.arrayBuffer();

    return Geobuf.decode(new Pbf(data));
  }
};

export const fetchDrawData = async (region, dataType) => {
  logFunction('DbIO.fetchDrawData', [region, dataType]);

  if (region === 'none' || dataType === '') {
    throw new Error("'one of region, dataType or format_type is None'");
  }
  const year = dataType === 'election' || dataType === 'school' ? 2018 : 2019;
  const params = new URLSearchParams([
    ['dataType', dataType],
    ['regionID', regionIDs[region]],
    ['year', year],
  ]);

  const response = await ky.get(api_url_db + '/drawdata', {
    searchParams: params,
  });

  return await response.json();
};

export const fetchDetailData = async (desoID, dataType) => {
  logFunction('DbIO.fetchDetailData', [desoID, dataType]);

  if (desoID === 'none' || dataType === '') {
    throw new Error("'one of region, dataType or format_type is None'");
  }
  const year = dataType === 'election' || dataType === 'school' ? 2018 : 2019;
  const params = new URLSearchParams([
    ['dataType', dataType],
    ['desoID', desoID],
    [['year'], year],
  ]);
  const response = await ky.get(api_url_db + '/details', {
    searchParams: params,
  });
  return await response.json();
};

export const downloadBorderGeometries = async (
  updateCountyBorders,
  updateNationBorders
) => {
  const countyBorderRequest = await ky.get(
    api_url_db + '/borders?borderType=county'
  );
  const preSignedUrlCounty = await countyBorderRequest.json();
  const data = await ky.get(preSignedUrlCounty);
  const countyBordersRaw = await data.json();

  const countyBorders = Object.assign(
    ...countyBordersRaw.features.map((borders) => {
      return {
        ['ID_' + borders.properties.LnKod]: borders,
      };
    })
  );
  updateCountyBorders(countyBorders);

  const nationBorderRequest = await ky.get(
    api_url_db + '/borders?borderType=nation'
  );

  const preSignedUrlNation = await nationBorderRequest.json();
  const nationRaw = await ky.get(preSignedUrlNation);
  const nationBordersRaw = await nationRaw.json();

  updateNationBorders(nationBordersRaw);
};
