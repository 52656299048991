import React, { useEffect, useReducer } from 'react';
import DeckGL from 'deck.gl';
import { StaticMap } from 'react-map-gl';

import {
  onClick,
  onViewStateChange,
  layersReducer,
} from '../../util/MapComponentUtil';

const LargeScreenMapComponent = ({
  deckGlConf,
  highlight_data,
  updateMetaData,
  updateCoord,
  nbrSearched,
}) => {
  const [layers, layersDispatch] = useReducer(layersReducer, []);

  // this is maybe a little sketchy, find a good way to add initLayers
  // to the dependecy list of useEffect without causing infinite re-renders
  const initLayers = () => {
    layersDispatch({
      type: 'init',
      payload: {
        deckGlConf,
        highlight_data,
      },
    });
  };

  const _onClick = (info, _) =>
    onClick(info, deckGlConf.dataTypeGroup, updateMetaData);

  // const _onHover = (info) => onHover(info, setHoverInfo);

  // const _renderTooltip = () => renderTooltip();

  const _onViewStateChange = ({ viewState, interactionState }) =>
    onViewStateChange(interactionState, viewState, updateCoord);

  useEffect(() => {
    if (deckGlConf.contentData !== null && deckGlConf.regionData !== 'none') {
      initLayers();
    }
  }, [deckGlConf]);

  useEffect(() => {
    if (nbrSearched > 1) {
      initLayers();
    }
  }, [nbrSearched]);

  useEffect(() => {
    if (highlight_data !== null) {
      initLayers();
    }
  }, [highlight_data]);

  return (
    <div>
      <DeckGL
        style={{ overflow: 'hidden' }}
        onClick={_onClick}
        onViewStateChange={_onViewStateChange}
        initialViewState={{
          longitude: deckGlConf.coordinate.lng,
          latitude: deckGlConf.coordinate.lat,
          zoom: deckGlConf.coordinate.zoom,
          pitch: 0,
          bearing: 0,
        }}
        height={'100%'}
        width={'100%'}
        controller={true}
        layers={layers}
      >
        <StaticMap mapStyle='https://api.maptiler.com/maps/c2e79edb-6ade-4864-89c5-b21a91cc9c55/style.json?key=R66sJIEjSIeJMOeJ8zUl' />
      </DeckGL>
    </div>
  );
};

export default LargeScreenMapComponent;
