import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  grid: {
    position: 'absolute',
    bottom: '10px',
    textAlign: 'center',
    width: '110px',
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    position: 'relative ',
    zIndex: 10,
    minWidth: '20px',
    marginTop: '0.5vh',
    marginLeft: '0.5vh',
    marginBottom: '0.5vh',
    width: '110px',
    borderRadius: '0px',
  },
  list: {
    padding: '10px',
  },
  colorButton: {
    opacity: 0.6,
    pointerEvents: 'none',
    borderRadius: '0px',
    padding: '0px',
    marginRight: '0px',
    width: '18px',
    minWidth: '18px',
    maxWidth: '18px',
    height: '18px',
  },
}));
