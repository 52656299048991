import { samstatTheme } from '../../themes/SamstatTheme';
import { hexToRGB } from '../../util/ColorPaletteUtil';

export const COLOR_SCALE_GENDER = [
  samstatTheme.palette.primary.dark,
  '#ad1457',
  '#e91e63',
  '#ec407a',
  '#f06292',
  '#f48fb1',
  '#f8bbd0',
  '#b2f9fd',
  '#6ddcf8',
  '#2c99d1',
  '#1e74af',
  '#12538d',
  '#0a3b75',
];

export const COLOR_SCALE_GENDER_RGB = hexToRGB(COLOR_SCALE_GENDER);

export const LABELS_GENDER = [
  'Andel kvinnor',
  '60+%',
  '58-60%',
  '56-58%',
  '54-56%',
  '52-54%',
  '50-52%',
  '48-50%',
  '46-48%',
  '44-46%',
  '42-46%',
  '42-44%',
  '42-%',
];

export const TOOLTIP_GENDER = 'Andel kvinnor i ett område';

export const GENDER_COLORS = {
  colorScale: COLOR_SCALE_GENDER,
  colorScaleRgb: COLOR_SCALE_GENDER_RGB,
  colorLabels: LABELS_GENDER,
  tooltip: TOOLTIP_GENDER,
};
