import { GeoJsonLayer } from '@deck.gl/layers';
import { IconLayer } from '@deck.gl/layers';

import { DATATYPES } from '../data/DATATYPES';

import { COLOR_SCALE_AGE_RGB } from '../styles/mapStyles/ageStyle';
import { COLOR_SCALE_GENDER_RGB } from '../styles/mapStyles/genderStyle';
import { COLOR_SCALE_BG_RGB } from '../styles/mapStyles/bgStyle';
import { COLOR_SCALE_EDUC_RGB } from '../styles/mapStyles/educStyle';
import { COLOR_SCALE_ELECTION_RGB } from '../styles/mapStyles/electionStyle';
import { COLOR_SCALE_INCOME_RGB } from '../styles/mapStyles/incStyle';
import { COLOR_SCALE_WORK_RGB } from '../styles/mapStyles/workStyle';
import { COLOR_SCALE_SCHOOL_RGB } from '../styles/mapStyles/schoolStyle';
import { logFunction } from './LogUtil';

export const createDataTypeSpecifics = (dataType) => {
  logFunction('TypeMetaDataUtil.createDataTypeSpecifics', [dataType]);
  switch (dataType) {
    case DATATYPES.school:
      return {
        drawColorScale: COLOR_SCALE_SCHOOL_RGB,
        deckGlLayerCls: IconLayer,
      };
    case DATATYPES.age:
      return {
        drawColorScale: COLOR_SCALE_AGE_RGB,
        deckGlLayerCls: GeoJsonLayer,
      };
    case DATATYPES.gender:
      return {
        drawColorScale: COLOR_SCALE_GENDER_RGB,
        deckGlLayerCls: GeoJsonLayer,
      };
    case DATATYPES.Bakgrund:
      return {
        drawColorScale: COLOR_SCALE_BG_RGB,
        deckGlLayerCls: GeoJsonLayer,
      };
    case DATATYPES.income:
      return {
        drawColorScale: COLOR_SCALE_INCOME_RGB,
        deckGlLayerCls: GeoJsonLayer,
      };
    case DATATYPES.work:
      return {
        drawColorScale: COLOR_SCALE_WORK_RGB,
        deckGlLayerCls: GeoJsonLayer,
      };
    case DATATYPES.educ:
      return {
        drawColorScale: COLOR_SCALE_EDUC_RGB,
        deckGlLayerCls: GeoJsonLayer,
      };
    case DATATYPES.val:
      return {
        drawColorScale: COLOR_SCALE_ELECTION_RGB,
        deckGlLayerCls: GeoJsonLayer,
      };
    default:
      return;
  }
};
