import { geoContains } from 'd3-geo';
import { regionIDs } from '../../data/Regions';
import { logFunction } from '../../util/LogUtil';

export const isSelectedCounty = (coordinate, countyBorder) => {
  return geoContains(countyBorder, [coordinate.lng, coordinate.lat]);
};

export const getCountyName = (selectedCountyId) => {
  return Object.entries(regionIDs)
    .filter(([_, countyID]) => selectedCountyId === countyID)
    .map(([countyName, _]) => countyName);
};

export const validateSearch = (userGeneratedAddress, region) => {
  logFunction('NavigationComponent.validateUserGeneratedAddress');
  if (userGeneratedAddress.toLowerCase().includes('sverige')) {
    alert(
      "Sökområde inehåller 'Sverige', men vi stödjer för närvarande inte visualisering av hela riket samtidigt. Vänligen sök på valfritt område inom Sverige istället."
    );
    return false;
  }
  if (emptyAddressInitialSearch(userGeneratedAddress, region)) {
    alert('Inget område har skrivits in i sökrutan!');
    return false;
  } else if (noDataTypeSelected()) {
    alert('Ingen datatyp har valts!');
    return false;
  }

  return true;
};

const emptyAddressInitialSearch = (userGeneratedAddress, region) =>
  userGeneratedAddress === '' && region === 'none';

const noDataTypeSelected = (dataType) => dataType === '';
