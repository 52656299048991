export const styles = (theme) => ({
  root: {},
  searchComponentStyle: {
    padding: '0.4vh 1vh',
    width: '100%',
    height: '100%',
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  spanStyle: {
    backgroundColor: theme.palette.secondary.main,
  },
  inputBaseStyle: {
    width: '100%',
  },
  iconButtonStyle: {},
});
