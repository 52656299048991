import { createTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';
import teal from '@material-ui/core/colors/teal';

export const samstatTheme = createTheme({
  spacing: [0, 2, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  palette: {
    primary: {
      main: blueGrey[600],
      dark: blueGrey[900],
    },
    secondary: {
      main: grey[50],
      button: teal[300],
    },
  },
  typography: {
    h1: { fontFamily: 'KonSystem' },
    h2: { fontFamily: 'KonSystem' },
    h3: { fontFamily: 'KonSystem' },
    h4: { fontFamily: 'KonSystem' },
    h5: { fontFamily: 'KonSystem' },
    h6: { fontFamily: 'KonSystem' },
  },
});
