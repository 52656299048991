import { hexToRGB } from '../../util/ColorPaletteUtil';
import { samstatTheme } from '../../themes/SamstatTheme';

export const COLOR_SCALE_WORK = [
  samstatTheme.palette.primary.dark,
  '#006d2c',
  '#2ca25f',
  '#66c2a4',
  '#99d8c9',
  '#ccece6',
  '#fcbba1',
  '#fc9272',
  '#fb6a4a',
  '#de2d26',
  '#a50f15',
];

export const COLOR_SCALE_WORK_RGB = hexToRGB(COLOR_SCALE_WORK);

export const LABELS_WORK = [
  'Andel Förv.',
  '90-100%',
  '80-90%',
  '70-80%',
  '60-70%',
  '50-60%',
  '40-50%',
  '30-40%',
  '20-30%',
  '10-20%',
  '0-10%',
];

export const TOOLTIP_WORK = 'Andel ej förvärvsarbetande i ett område';

export const WORK_COLORS = {
  colorScale: COLOR_SCALE_WORK,
  colorScaleRgb: COLOR_SCALE_WORK_RGB,
  colorLabels: LABELS_WORK,
  tooltip: TOOLTIP_WORK,
};
