import React from 'react';

import { Button, Fade } from '@material-ui/core';

import { samstatTheme } from '../../themes/SamstatTheme';

export const MapButton = () => {
  return (
    <Fade appear={true} in={true} timeout={4000}>
      <Button
        style={{
          backgroundColor: samstatTheme.palette.primary.main,
          color: 'white',
          borderRadius: 0,
          marginBottom: '50px',
        }}
        href={'/karta'}
      >
        till kartan
      </Button>
    </Fade>
  );
};
