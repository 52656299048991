import { DATATYPES } from '../../data/DATATYPES';

import { AGE_COLORS } from '../../styles/mapStyles/ageStyle';
import { BACKGROUND_COLORS } from '../../styles/mapStyles/bgStyle';
import { EDUCATION_COLORS } from '../../styles/mapStyles/educStyle';
import { ELECTION_COLORS } from '../../styles/mapStyles/electionStyle';
import { GENDER_COLORS } from '../../styles/mapStyles/genderStyle';
import { INCOME_COLORS } from '../../styles/mapStyles/incStyle';
import { WORK_COLORS } from '../../styles/mapStyles/workStyle';
import { SCHOOL_COLORS } from '../../styles/mapStyles/schoolStyle';

export const emptyLegendData = {
  colorScale: [],
  colorLabels: [],
  tooltip: '',
};

export const legendDataFromDataType = (dataType) => {
  switch (dataType) {
    case DATATYPES.age:
      return AGE_COLORS;
    case DATATYPES.gender:
      return GENDER_COLORS;
    case DATATYPES.Bakgrund:
      return BACKGROUND_COLORS;
    case DATATYPES.income:
      return INCOME_COLORS;
    case DATATYPES.work:
      return WORK_COLORS;
    case DATATYPES.educ:
      return EDUCATION_COLORS;
    case DATATYPES.val:
      return ELECTION_COLORS;
    case DATATYPES.school:
      return SCHOOL_COLORS;
    default:
      return null;
  }
};
