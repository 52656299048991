export const regionIDs = {
  'Stockholm County': 'ID_01',
  'Uppsala County': 'ID_03',
  'Södermanland County': 'ID_04',
  'Östergötland County': 'ID_05',
  'Jonkoping County': 'ID_06',
  'Kronoberg County': 'ID_07',
  'Kalmar County': 'ID_08',
  'Gotland County': 'ID_09',
  'Blekinge County': 'ID_10',
  'Skåne County': 'ID_12',
  'Halland County': 'ID_13',
  'Västra Götaland County': 'ID_14',
  'Varmland County': 'ID_17',
  'Örebro County': 'ID_18',
  'Västmanland County': 'ID_19',
  'Dalarna County': 'ID_20',
  'Gavleborg County': 'ID_21',
  'Västernorrland County': 'ID_22',
  'Jamtland County': 'ID_23',
  'Västerbotten County': 'ID_24',
  'Norrbotten County': 'ID_25',
};
