export const dataTypeGroups = {
  age: 'scb',
  gender: 'scb',
  background: 'scb',
  income: 'scb',
  work: 'scb',
  education: 'scb',
  election: 'election',
  school: 'school',
};
