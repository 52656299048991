import React from 'react';
import { Hidden } from '@material-ui/core';

import SmallScreenMapComponent from './SmallScreenMapComponent';
import LargeScreenMapComponent from './LargeScreenMapComponent';

const MapComponent = (props) => {
  return (
    <div>
      <Hidden mdUp>
        <SmallScreenMapComponent {...props} />
      </Hidden>
      <Hidden smDown>
        <LargeScreenMapComponent {...props} />
      </Hidden>
    </div>
  );
};

export default MapComponent;
