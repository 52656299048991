import React from 'react';

import { createMetaDataTable } from './MetadataUtil';

const categoryLabels = ['Förvärvsarbetande', 'Ej förärvsarbetande'];
const peopleByCategory = (desoContent) => [
  desoContent['förvärvsarbetande'],
  desoContent['ej förvärvsarbetande'],
];

const WorkMetadata = ({ desoContent }) => {
  return (
    <div>
      {createMetaDataTable(
        ['Sysselsättningsgrupp', 'Andel', 'Antal'],
        categoryLabels,
        peopleByCategory(desoContent),
        desoContent.totalt
      )}
      <br />
      <span>{'Källa och aktualitet: SCB 2019.'}</span>
    </div>
  );
};

export default WorkMetadata;
