import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  metaDataPaperStyle: {
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    zIndex: 9001,
    display: 'flex',
    width: '100%',
    elevation: 0,
  },
  metaDataAccordionStyle: {
    backgroundColor: theme.palette.secondary.main,
  },
  metaDataAccordionDetailsStyle: {
    padding: theme.spacing(0, 0, 0, 0),
    backgroundColor: theme.palette.secondary.main,
  },
  metaDataAccordionSummaryStyle: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0, 5, 0, 5),
  },
}));
