import React from 'react';

import { createMetaDataTable } from './MetadataUtil';

const categoryLabels = ['Antal kvinnor', 'Antal män'];
const peopleByCategory = (desoContent) => [
  desoContent['kvinnor'],
  desoContent['män'],
];

const GenderMetadata = ({ desoContent }) => {
  return (
    <div>
      {createMetaDataTable(
        ['Kön', 'Andel', 'Antal'],
        categoryLabels,
        peopleByCategory(desoContent),
        desoContent.totalt
      )}
      <br />
      <span>{'Källa och aktualitet: SCB 2019.'}</span>
    </div>
  );
};

export default GenderMetadata;
