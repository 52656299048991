import React from 'react';

const SchoolMetadata = ({ desoContent }) => {
  const studentsPerTeacher = (schoolData) => {
    let value = null;
    for (const dataEntry of schoolData.studentsPerTeacherQuota) {
      if (dataEntry.valueType === 'EXISTS') {
        value = dataEntry.value;
        break;
      }
    }
    return value;
  };

  const certifiedTeachersQuota = (schoolData) => {
    let value = null;
    for (const dataEntry of schoolData.certifiedTeachersQuota) {
      if (dataEntry.valueType === 'EXISTS') {
        value = dataEntry.value;
        break;
      }
    }
    return value;
  };

  const totalNumberOfPupils = (schoolData) => {
    let value = null;
    for (const dataEntry of schoolData.totalNumberOfPupils) {
      if (dataEntry.valueType === 'EXISTS') {
        value = dataEntry.value;
        break;
      }
    }
    return value;
  };

  return (
    desoContent !== undefined && (
      <div>
        <h3>{desoContent.name}</h3>
        <table style={{ width: '100%', marginLeft: '-3px' }}>
          <thead>
            <tr>
              <td>Nyckeltal</td>
              <td>Värde</td>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'left' }}>
            <tr>
              <td>Studenter per lärare </td>
              <td>{studentsPerTeacher(desoContent)}</td>
            </tr>
            <tr>
              <td>Andel behöriga lärare </td>
              <td>{certifiedTeachersQuota(desoContent)} %</td>
            </tr>
            <tr>
              <td>Antal studenter </td>
              <td>{totalNumberOfPupils(desoContent)}</td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <span>{'Källa och aktualitet: Skolverket 2019-08-01.'}</span>
      </div>
    )
  );
};

export default SchoolMetadata;
