import React, { useState, useEffect } from 'react';

import { createMetaDataTable } from './MetadataUtil';

const categoryLabels = ['Grundskoleexamen', 'Gymnasieexamen', 'Högskoleexamen'];
const getPeopleByCategory = (desoContent) => [
  desoContent['förgymnasial utbildning'],
  desoContent['gymnasial utbildning'],
  desoContent['eftergymnasial utbildning, mindre än 3 år'] +
    desoContent['eftergymnasial utbildning, 3 år eller mer'],
];

const EducationMetadata = ({ desoContent }) => {
  const [elementShouldBold, setElementShouldBold] = useState(0);
  const [peopleByCategory, setPeopleByCategory] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const peopleByCategory = getPeopleByCategory(desoContent);
    const maxIndex = peopleByCategory.indexOf(Math.max(...peopleByCategory));
    const elementShouldBold = new Array(categoryLabels.length)
      .fill(0)
      .map((_, index) => (index === maxIndex ? 1 : 0));
    setElementShouldBold(elementShouldBold);
    setPeopleByCategory(peopleByCategory);
    setTotal(peopleByCategory.reduce((acc, element) => acc + element));
  }, [desoContent]);

  return (
    <div>
      {createMetaDataTable(
        ['Utildningsnivå', 'Andel', 'Antal'],
        categoryLabels,
        peopleByCategory,
        total,
        elementShouldBold
      )}
      <br />
      <span>{'Källa och aktualitet: SCB 2019.'}</span>
    </div>
  );
};

export default EducationMetadata;
