import {
  genGeoJsonLayerSettings,
  genIconLayerSettings,
} from '../styles/mapStyles/genLayerStyle';
import { fetchDrawData } from './DbIO';
import { fetchRegion } from './DbIO';
import { logFunction } from './LogUtil';
import { createDataTypeSpecifics } from './TypeMetaDataUtil';

import { GeoJsonLayer } from '@deck.gl/layers';
import { DATATYPES } from '../data/DATATYPES';
import DeckGlConf from '../structures/DeckGlConf';

import flattenSchoolData from './FlattenSchoolData';

const HIGLIGHTED_LIGHT_SETTINGS = {
  lightsPosition: [-125, 50.5, 5000, -122.8, 48.5, 8000],
  ambientRation: 0.2,
  diffuseRatio: 0.5,
  specularRatio: 0.3,
  lightsStrength: [1.0, 0.0, 2.0, 0.0],
  numberOfLights: 2,
};

const ICON_MAPPING = {
  school_marker: { x: 0, y: 0, width: 96, height: 152, mask: true },
};

export const createDeckGlLayer = (deckGlConf, visibility) => {
  logFunction('NavigationComponent.createDeckGlLayer', [
    deckGlConf,
    visibility,
  ]);
  if (deckGlConf.dataType === DATATYPES.school) {
    return new deckGlConf.dataTypeSpecifics.deckGlLayerCls({
      id: deckGlConf.dataType + deckGlConf.region,
      data: flattenSchoolData(deckGlConf.contentData.region_data),
      ...genIconLayerSettings,
      visible: visibility,
      iconMapping: ICON_MAPPING,
      getIcon: (d) => 'school_marker',
      getColor: (d) => {
        switch (d.typeOfSchooling[d.typeOfSchooling.length - 1].code) {
          case 'gy':
            return deckGlConf.dataTypeSpecifics.drawColorScale[3];
          case 'gr':
            return deckGlConf.dataTypeSpecifics.drawColorScale[2];
          case 'fsk':
            return deckGlConf.dataTypeSpecifics.drawColorScale[1];
          default:
            break;
        }
      },
      getPosition: (d) => [
        parseFloat(d.wgs84_Long),
        parseFloat(d.wgs84_Lat),
        0,
      ],
      getSize: 10,
    });
  } else {
    return new deckGlConf.dataTypeSpecifics.deckGlLayerCls({
      id: deckGlConf.region,
      data: deckGlConf.regionData,
      ...genGeoJsonLayerSettings,
      visible: visibility,
      updateTriggers: {
        getFillColor: deckGlConf.contentData.region_data,
      },
      getFillColor: (region) => {
        const index =
          region.properties.Deso !== undefined
            ? deckGlConf.contentData.region_data[region.properties.Deso]
            : deckGlConf.contentData.region_data[region.properties.VD];
        const colorScale = deckGlConf.dataTypeSpecifics.drawColorScale;
        const color = colorScale[index + 1];
        return color;
      },
    });
  }
};

export const createDeckGlConf = async (
  region,
  dataType,
  dataTypeGroup,
  getDataOnly,
  coordinate
) => {
  logFunction('deckGlUtil.createDeckGlConf', [
    region,
    dataType,
    getDataOnly,
    coordinate,
  ]);
  const dataTypeSpecifics = createDataTypeSpecifics(dataType);
  const fetchedData = await fetchDrawData(region, dataType);
  return new DeckGlConf(
    dataType,
    region,
    getDataOnly || dataType === DATATYPES.school
      ? null
      : await fetchRegion(region, dataTypeGroup),
    fetchedData,
    dataTypeSpecifics,
    dataTypeGroup,
    coordinate
  );
};

export const createHighlightedLayer = (data, opacity, visibility) => {
  return new GeoJsonLayer({
    id: 'highlight',
    data: data,
    opacity: opacity,
    stroked: true,
    filled: true,
    extruded: false,
    wireframe: false,
    fp64: true,
    lightSettings: HIGLIGHTED_LIGHT_SETTINGS,
    getElevation: 10,
    getFillColor: [0, 0, 0],
    pickable: false,
    lineWidthScale: 10,
    lineWidthMinPixels: 5,
    getLineWidth: 5,
    visible: visibility,
    updateTriggers: { visible: visibility },
  });
};
