export const DATATYPES = {
  age: 'age',
  gender: 'gender',
  Bakgrund: 'background',
  income: 'income',
  work: 'work',
  educ: 'education',
  val: 'election',
  school: 'school',
};
