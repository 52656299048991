import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import ky from 'ky';
import {
  Grid,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@material-ui/core';

import { regionIDs } from '../../data/Regions';
import { api_url } from '../../data/ApiURL';
import { TYPEGROUPS } from '../../data/TYPEGROUPS';
import { dataTypeGroups } from '../../data/DataTypeGroups';
import { createDeckGlConf } from '../../util/deckGLUtil';
import { styles } from '../../styles/componentStyles/MapStyle';
import { logFunction } from '../../util/LogUtil';
import { validateSearch, isSelectedCounty, getCountyName } from './SearchUtil';
import { DATATYPES } from '../../data/DATATYPES';

import SearchBar from './SearchBar';
import SelectBoxComponent from './SelectBox';
import MetaDataComponent from '../metadata/Metadata';
import SearchButtonComponent from './SearchButton';
import Coordinate from '../../structures/Coordinate';
import About from './About';
import LoadSelectedRegionButton from '../../components/LoadSelectedRegionButton';

class NavigationComponent extends React.Component {
  state = {
    region: 'none',
    dataType: '',
    dataTypeGroup: TYPEGROUPS.SCB,
    expandedPanel: true,
    address: '',
    showAbout: false,
  };

  submitSearch = () => {
    logFunction('NavigationComponent.submitSearch');
    const addressIsValid = validateSearch(
      this.state.address,
      this.state.region
    );
    if (this.state.dataType === '') {
      alert('Ingen datatyp är vald!');
      return;
    }
    if (addressIsValid) {
      console.log('addressIsValid');
      if (this.shouldLoadSelectedRegion()) {
        this.loadSelectedRegion();
      } else {
        this.loadRegionFromSearch();
      }
      this.changePanelState();
    }
  };

  loadRegionFromSearch = async () => {
    logFunction('NavigationComponent.loadRegionFromSearch', []);
    this.props.toggleLoading();
    const geocodingEndpoint =
      api_url + '/geolocation?address=' + this.state.address + ', sweden';
    let response = null;
    try {
      response = await ky.get(geocodingEndpoint);
    } catch (error) {
      console.log(error);
      alert('Område kan inte hittas!');
      this.props.toggleLoading();
      this.changePanelState();
      return;
    }

    const data = await response.json();

    if (data.region in regionIDs) {
      const newCoordinate = new Coordinate(data.lat, data.lng, 12);
      this.setDeckGlConf(data.region, newCoordinate);
      this.setState({ region: data.region });
    } else {
      alert('Område kan inte hittas!');
      this.props.toggleLoading();
      this.changePanelState();
    }
  };

  loadSelectedRegion = () => {
    logFunction('NavigationComponent.loadSelectedRegion');
    console.log(this.props.countyBorders);
    this.props.toggleLoading();
    Object.values(this.props.countyBorders)
      .filter((countyBorders) =>
        isSelectedCounty(this.props.coordinate, countyBorders)
      )
      .map((countyBorders) => 'ID_' + countyBorders.properties.LnKod)
      .flatMap((selectedCountyId) => getCountyName(selectedCountyId))
      .forEach((region) => {
        this.setState({ region });
        this.setDeckGlConf(region, this.props.coordinate);
      });
  };

  setDeckGlConf = async (region, newCoordinate) => {
    logFunction('NavigationComponent.setDeckGlConf', [region, newCoordinate]);
    const dataOnlyUpdate = this.isDataOnlyUpdate(region);
    const newDataTypeGroup = dataTypeGroups[this.state.dataType];
    const newDeckGlConf = await createDeckGlConf(
      region,
      this.state.dataType,
      newDataTypeGroup,
      dataOnlyUpdate,
      newCoordinate
    );
    this.setState({ address: '', dataTypeGroup: newDataTypeGroup });
    this.props.setDeckGlConf(newDeckGlConf);
  };

  isDataOnlyUpdate = (region) => {
    const newDataTypeGroup = dataTypeGroups[this.state.dataType];
    return (
      (this.state.region === region &&
        this.state.dataTypeGroup === newDataTypeGroup) ||
      this.state.dataType === DATATYPES.school
    );
  };

  changePanelState = () => {
    logFunction('NavigationComponent.changePanelState');
    this.setState({
      expandedPanel: !this.state.expandedPanel,
    });
  };

  handleTextChange = (address, key) => {
    this.setState({
      address,
    });
    if (key === 'Enter') {
      this.submitSearch();
    }
  };

  handleSelectChange = (event) => {
    logFunction('NavigationComponent.handleSelectChange', [event]);
    this.setState({
      dataType: event.target.value,
    });
  };

  shouldLoadSelectedRegion = () => {
    return this.state.address === '';
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.showAbout && (
          <About closeAbout={() => this.setState({ showAbout: false })} />
        )}
        {!this.state.showAbout && (
          <Grid
            id='navigationGrid'
            container
            item
            spacing={0}
            direction='column'
            xs={12}
            sm={5}
            md={4}
            lg={3}
            xl={2}
          >
            <Grid item className={classes.paddingChoose}>
              <Accordion
                onChange={this.changePanelState}
                expanded={this.state.expandedPanel}
                className={classes.expansionPanelStyle}
                spacing={0}
                square={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  className={classes.expansionPanelSummaryStyle}
                >
                  <Typography className={classes.heading} variant='h2'>
                    SAMSTAT
                  </Typography>
                  <InfoIcon
                    className={classes.infoIcon}
                    onClick={() => this.setState({ showAbout: true })}
                  />
                </AccordionSummary>
                <AccordionDetails
                  className={classes.expansionPanelDetailsFirstStyle}
                >
                  <SearchBar
                    updateAddress={this.handleTextChange}
                    userGeneratedAddress={this.state.address}
                  />
                </AccordionDetails>
                <AccordionDetails
                  className={classes.expansionPanelDetailsStyle}
                >
                  <SelectBoxComponent
                    handleSelectChange={this.handleSelectChange}
                    value={this.state.dataType}
                  />
                </AccordionDetails>
                <AccordionDetails
                  className={classes.expansionPanelDetailsLastStyle}
                >
                  <SearchButtonComponent
                    handleButtonClick={this.submitSearch}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item className={classes.paddingChoose}>
              {this.props.metaDataVisibile && (
                <MetaDataComponent
                  metadata={this.props.metadata}
                  dataType={this.state.dataType}
                  nbrSearched={this.props.nbrSearched}
                />
              )}
            </Grid>
          </Grid>
        )}
        {this.props.displayLoadSelectedButton && (
          <LoadSelectedRegionButton
            loadSelectedRegion={this.loadSelectedRegion}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(NavigationComponent);
