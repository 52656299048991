import { samstatTheme } from '../../themes/SamstatTheme';
import { hexToRGB } from '../../util/ColorPaletteUtil';

// export const COLOR_SCALE_AGE = [
//   samstatTheme.palette.primary.dark,
//   '#2a8104',
//   '#508104',
//   '#cfba00',
//   '#9e8e01',
//   '#ffc926',
//   '#e89d2e',
//   '#db8200',
//   '#d16526',
//   '#b64201',
// ];

export const COLOR_SCALE_AGE = [
  samstatTheme.palette.primary.dark,
  '#ffffff',
  '#f3ece3',
  '#e6d9c7',
  '#dac7ac',
  '#ccb592',
  '#bfa478',
  '#b1925f',
  '#a28146',
  '#93712c',
];

export const COLOR_SCALE_AGE_RGB = hexToRGB(COLOR_SCALE_AGE);

export const TOOLTIP_AGE = 'Störst åldersgrupp i ett område';

export const LABELS_AGE = [
  'Åldersgrupp',
  '0-9',
  '10-19',
  '20-29',
  '30-39',
  '40-49',
  '50-59',
  '60-69',
  '70-79',
  '80+',
];

export const AGE_COLORS = {
  colorScale: COLOR_SCALE_AGE,
  colorScaleRgb: COLOR_SCALE_AGE_RGB,
  colorLabels: LABELS_AGE,
  tooltip: TOOLTIP_AGE,
};
