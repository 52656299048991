import { samstatTheme } from '../../themes/SamstatTheme';
import { hexToRGB } from '../../util/ColorPaletteUtil';

export const COLOR_SCALE_SCHOOL = [
  samstatTheme.palette.primary.dark,
  '#79111E',
  '#BDD59D',
  '#539193',
];

export const COLOR_SCALE_SCHOOL_RGB = hexToRGB(COLOR_SCALE_SCHOOL);

export const LABELS_SCHOOL = ['Skoltyp', 'Förs.', 'Grund.', 'Gymn.'];

export const TOOLTIP_SCHOOL = 'Typ av skola';

export const SCHOOL_COLORS = {
  colorScale: COLOR_SCALE_SCHOOL,
  colorScaleRgb: COLOR_SCALE_SCHOOL_RGB,
  colorLabels: LABELS_SCHOOL,
  tooltip: TOOLTIP_SCHOOL,
};
