export const styles = (theme) => ({
  loadSelectedRegionButtonGrid: {
    position: 'absolute',
    // bottom: '20px',
    textAlign: 'center',
  },
  loadSelectedRegionButton: {
    margin: '10px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
});
