import React from 'react';

const IncomeMetadata = ({ desoContent }) => {
  return (
    <div>
      <table style={{ width: '100%', marginLeft: '-3px' }}>
        <tbody>
          <tr style={{ textAlign: 'left' }}>
            <th>{'Medianinkomst'}</th>
            <th>{'Medianinkomst i riket'}</th>
          </tr>
          <tr>
            <td>
              {Math.round(
                (desoContent.nettoinkomst.totalt.mediantkr * 1000) / 12
              ) + ' kr/månad'}
            </td>
            <td>{'23350 kr/månad'}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <span>{'Urval: Befolkning 20+ år (ej kapitalinkomst).'}</span>
      <br />
      <span>{'Källa och aktualitet: SCB 2019.'}</span>
    </div>
  );
};

export default IncomeMetadata;
