import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import './App.css';
import MapView from './views/MapView';
import InfoView from './views/InfoView';
import LandingView from './views/LandingView';
import { samstatTheme } from './themes/SamstatTheme';

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={samstatTheme}>
          <Switch>
            <Route exact path='/om' component={InfoView} />
            <Route exact path='/karta' component={MapView} />
            <Route exact path='/' component={LandingView} />
          </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}
