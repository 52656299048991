import React from 'react';
import {Grid} from '@material-ui/core';
import {Adsense} from '@ctrl/react-adsense';

import AppBar from '../components/landing/AppBar';
import Greeting from '../components/landing/Greeting';
import InfoBar from '../components/landing/InfoBar';
import {MapButton} from '../components/landing/MapButton';

const LandingView = () => {
    return (
        <div style={{body: {overflow: 'visible'}}}>
            <AppBar/>
            <Grid
                container
                direction='column'
                justifyContent='center'
                align='center'
                style={{width: '100vw'}}
            >
                <Grid item>
                    <Greeting/>
                </Grid>
                <Grid item>
                    <MapButton/>
                </Grid>
                <Grid item style={{margin: '5px'}}>
                    <InfoBar
                        // title='Välkommen till samstat!'
                        text='Samstat är ett verktyg för visualisering av svensk samhällsstatistik. Sverige tillhandahåller en mängd öppna dataset och APIer, men för att ta del av informationen krävs ofta expertkunskap och specialiserade verktyg. Vi ser det som vårat uppdrag att göra den svenska offentliga datan tillgänglig till gemene man.'
                        flex='center'
                    />
                </Grid>
                <Grid item style={{margin: '5px'}}>
                    <Adsense
                        client="ca-pub-6064754017327276" // Replace with your AdSense client ID
                        slot="6150216971" // Replace with your ad slot ID
                        style={{display: 'block'}}
                        format="auto"
                        responsive="true"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default LandingView;
