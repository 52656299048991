import React from 'react';

export const sortCategoriesAndLabels = (nbrPeoplInCategory, categoryLabels) => {
  const sortedCategoryAndLabels = nbrPeoplInCategory
    .map((element, index) => [element, index])
    .sort((left, right) =>
      left[0] > right[0] ? -1 : left[0] < right[0] ? 1 : 0
    )
    .map(([element, index]) => [element, categoryLabels[index]]);

  const sortedCategories = sortedCategoryAndLabels.map(
    ([_, category]) => category
  );

  const sortedLabels = sortedCategoryAndLabels.map(([label, _]) => label);
  return [sortedCategories, sortedLabels];
};

export const createMetaDataTable = (
  headings,
  categoryLabels,
  peopleByCategory,
  total,
  elementShouldBold = new Array(categoryLabels.length).fill(0)
) => {
  return (
    <table style={{ width: '100%', marginLeft: '-3px' }}>
      <tbody>
        <tr style={{ textAlign: 'left' }}>
          {headings.map((heading, headingIndex) => (
            <th key={headingIndex}>{heading}</th>
          ))}
        </tr>
        {categoryLabels
          .map((category, index) => {
            return [
              category,
              Number((peopleByCategory[index] / total) * 100).toFixed(0) + '%',
              peopleByCategory[index],
            ];
          })
          .map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((rowElement, rowElementIndex) => {
                const fontWeight =
                  elementShouldBold[rowIndex] === 1 ? 'bold' : 'normal';
                return (
                  <td style={{ fontWeight: fontWeight }} key={rowElementIndex}>
                    {rowElement}
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
