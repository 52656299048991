import React, { useEffect, useState } from 'react';

import { createMetaDataTable } from './MetadataUtil';

const categoryLabels = [
  '0 - 9',
  '10 - 19',
  '20 - 29',
  '30 - 39',
  '40 - 49',
  '50 - 59',
  '60 - 69',
  '70 - 79',
  '80+',
];

const getNbrPeopleInCategory = (desoContent) => {
  return [
    desoContent['0-4 år'] + desoContent['5-9 år'],
    desoContent['10-14 år'] + desoContent['15-19 år'],
    desoContent['20-24 år'] + desoContent['25-29 år'],
    desoContent['30-34 år'] + desoContent['35-39 år'],
    desoContent['40-44 år'] + desoContent['45-49 år'],
    desoContent['50-54 år'] + desoContent['55-59 år'],
    desoContent['60-64 år'] + desoContent['65-69 år'],
    desoContent['70-74 år'] + desoContent['75-79 år'],
    desoContent['80- år'],
  ];
};

const calculateAverageAge = (desoContent) => {
  return (
    ((desoContent['0-4 år'] + desoContent['5-9 år']) * 5 +
      (desoContent['10-14 år'] + desoContent['15-19 år']) * 15 +
      (desoContent['20-24 år'] + desoContent['25-29 år']) * 25 +
      (desoContent['30-34 år'] + desoContent['35-39 år']) * 35 +
      (desoContent['40-44 år'] + desoContent['45-49 år']) * 45 +
      (desoContent['50-54 år'] + desoContent['55-59 år']) * 55 +
      (desoContent['60-64 år'] + desoContent['65-69 år']) * 65 +
      (desoContent['70-74 år'] + desoContent['75-79 år']) * 75 +
      desoContent['80- år'] * 85) /
    desoContent.totalt
  );
};

const AgeMetadata = ({ desoContent }) => {
  const [elementShouldBold, setElementShouldBold] = useState(0);
  const [peopleByCategory, setPeopleByCategory] = useState([]);

  useEffect(() => {
    const peopleByCategory = getNbrPeopleInCategory(desoContent);
    const maxIndex = peopleByCategory.indexOf(Math.max(...peopleByCategory));
    const elementShouldBold = new Array(categoryLabels.length)
      .fill(0)
      .map((_, index) => (index === maxIndex ? 1 : 0));
    setElementShouldBold(elementShouldBold);
    setPeopleByCategory(peopleByCategory);
  }, [desoContent]);

  return (
    <div>
      {createMetaDataTable(
        ['Åldersgrupp', 'Andel', 'Antal'],
        categoryLabels,
        peopleByCategory,
        desoContent.totalt,
        elementShouldBold
      )}
      <br />
      <span>
        {'Meddelålder: ' + Number(calculateAverageAge(desoContent).toFixed(0))}
      </span>
      <br />
      <span>{'Källa och aktualitet: SCB 2019'}</span>
    </div>
  );
};

export default AgeMetadata;
