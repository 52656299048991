import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export const BlackAndWhiteTooltip = withStyles({
  tooltip: {
    fontSize: 12,
    backgroundColor: 'black',
  },
  arrow: {
    color: 'black',
  },
})(Tooltip);
