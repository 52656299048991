import blueGrey from '@material-ui/core/colors/blueGrey';

export const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  backdrop: {
    position: 'absolute',
    zIndex: 11,
    color: '#ffffff',
  },
  paddingChoose: {
    paddingTop: '0.5vh',
    paddingLeft: '0.5vh',
    paddingRight: '0.5vh',
  },
  spinner: {
    textAlign: 'center',
    padding: '5px',
    position: 'absolute',
    zIndex: 12,
    color: theme.palette.primary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    color: blueGrey[600],
    paddingLeft: '1.61803398875vh',
  },
  expansionPanelStyle: {
    zIndex: 1,
    backgroundColor: theme.palette.secondary.main,
  },
  expansionPanelDetailsStyle: {
    padding: theme.spacing(2, 5, 2, 5),
    borderColor: theme.palette.primary.main,
  },
  expansionPanelDetailsFirstStyle: {
    padding: theme.spacing(0, 5, 2, 5),
  },
  expansionPanelDetailsLastStyle: {
    padding: theme.spacing(2, 5, 5, 5),
  },
  expansionPanelSummaryStyle: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0, 5, 0, 5),
  },
  infoIcon: {
    width: '20px',
    height: '20px',
    marginTop: '5px',
    paddingLeft: '10px',
    color: theme.palette.primary.main,
    right: '55px',
    position: 'absolute',
  },
  crossIcon: {
    color: theme.palette.primary.main,
  },
});
