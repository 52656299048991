import React from 'react';
import {CircularProgress} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import {withStyles} from '@material-ui/core/styles';
import {geoContains} from 'd3-geo';
import {Adsense} from '@ctrl/react-adsense';


import {logFunction} from '../util/LogUtil';
import {regionIDs} from '../data/Regions';
import {styles} from '../styles/componentStyles/MapStyle';
import {downloadBorderGeometries, fetchDetailData} from '../util/DbIO';
import MapComponent from '../components/map/MapComponent';
import NavigationComponent from '../components/navigation/NavigationComponent';
import ColorLegend from '../components/legend/ColorLegend';
import DeckGlConf from '../structures/DeckGlConf';

class MapView extends React.Component {
    state = {
        deckGlConf: new DeckGlConf(),
        isLoading: false,
        metaDataVisibile: false,
        metadata: null,
        highlight_data: null,
        nbrSearched: 0,
        displayLoadSelectedButton: false,
        countyBorders: null,
        nationBorders: null,
    };

    toggleLoading = () => {
        logFunction('toggleLoading');
        this.setState({
            isLoading: !this.state.isLoading,
            metaDataVisibile: false,
        });
    };

    updateOnClick = async (info, desoId) => {
        logFunction('MapView.updateOnClick', [info, desoId]);
        const metadata = await fetchDetailData(
            desoId,
            this.state.deckGlConf.dataType
        );
        metadata.feature.name = info.object.name;
        this.setState({
            metadata: metadata.feature,
            metaDataVisibile: true,
            highlight_data: info.object,
        });
    };

    shouldDisplayLoadSelectedButton = (coordinate) => {
        logFunction('MapView.shouldDisplayLoadSelectedButton', [coordinate]);
        if (this.state.countyBorders !== null) {
            const currentCountyBorder =
                this.state.countyBorders[regionIDs[this.state.deckGlConf.region]];
            const coordInSelectedRegion = geoContains(currentCountyBorder, [
                coordinate.lng,
                coordinate.lat,
            ]);
            const coordInSweden = geoContains(this.state.nationBorders, [
                coordinate.lng,
                coordinate.lat,
            ]);

            return !coordInSelectedRegion && coordInSweden;
        }
        return false;
    };

    updateCoord = (coordinate) => {
        logFunction('MapView.updateCoord');
        const shouldUpdate = this.shouldDisplayLoadSelectedButton(coordinate);
        this.setState({
            displayLoadSelectedButton: shouldUpdate,
            deckGlConf: new DeckGlConf(
                this.state.deckGlConf.dataType,
                this.state.deckGlConf.region,
                this.state.deckGlConf.regionData,
                this.state.deckGlConf.contentData,
                this.state.deckGlConf.dataTypeSpecifics,
                this.state.deckGlConf.dataTypeGroup,
                coordinate
            ),
        });
    };

    setDeckGlConf = async (newDeckGlConf) => {
        logFunction('MapView.setDeckGlConf', [newDeckGlConf]);
        if (this.shouldReuseRegionData(newDeckGlConf)) {
            newDeckGlConf.regionData = this.state.deckGlConf.regionData;
        }
        this.setState({
            deckGlConf: newDeckGlConf,
            nbrSearched: this.state.nbrSearched + 1,
            displayLoadSelectedButton: false,
            highlight_data: null,
        });
        this.maybeDownloadBorderGeometries();
        this.toggleLoading();
    };

    maybeDownloadBorderGeometries = () => {
        if (
            this.state.countyBorders === null &&
            this.state.nationBorders === null
        ) {
            downloadBorderGeometries(
                (countyBorders) => this.setState({countyBorders: countyBorders}),
                (nationBorders) => this.setState({nationBorders: nationBorders})
            );
        }
    };

    shouldReuseRegionData = (newDeckGlConf) => {
        return newDeckGlConf.regionData === null;
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <MapComponent
                    highlight_data={this.state.highlight_data}
                    updateMetaData={this.updateOnClick}
                    updateCoord={this.updateCoord}
                    deckGlConf={this.state.deckGlConf}
                    nbrSearched={this.state.nbrSearched}
                />
                <NavigationComponent
                    metaDataVisibile={this.state.metaDataVisibile}
                    metadata={this.state.metadata}
                    toggleLoading={this.toggleLoading}
                    countyBorders={this.state.countyBorders}
                    displayLoadSelectedButton={this.state.displayLoadSelectedButton}
                    setDeckGlConf={this.setDeckGlConf}
                    coordinate={this.state.deckGlConf.coordinate}
                    nbrSearched={this.state.nbrSearched}
                />
                {this.state.nbrSearched > 0 && (
                    <ColorLegend
                        dataType={this.state.deckGlConf.dataType}
                        nbrSearched={this.state.nbrSearched}
                    />
                )}
                {this.state.isLoading && (
                    <Backdrop className={classes.backdrop} open={true}>
                        <CircularProgress className={classes.spinner}/>
                    </Backdrop>
                )}
              <Adsense
                  client="ca-pub-6064754017327276" // Replace with your AdSense client ID
                  slot="6150216971" // Replace with your ad slot ID
                  style={{display: 'block'}}
                  format="auto"
                  responsive="true"
              />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(MapView);
