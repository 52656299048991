import { samstatTheme } from '../../themes/SamstatTheme';
import { hexToRGB } from '../../util/ColorPaletteUtil';

export const COLOR_SCALE_BG = [
  samstatTheme.palette.primary.dark,
  '#005ED6',
  '#1C6FD3',
  '#3880D1',
  '#5591CD',
  '#71A2CD',
  '#8DB3CA',
  '#AAC4C8',
  '#C6D5C6',
  '#E2E6C4',
  '#FFF7C2',
];

export const COLOR_SCALE_BG_RGB = hexToRGB(COLOR_SCALE_BG);

export const LABELS_BACKGROUND = [
  'Utl. bakgrund',
  '90-100%',
  '80-90%',
  '70-80%',
  '60-70%',
  '50-60%',
  '40-50%',
  '30-40%',
  '20-30%',
  '10-20%',
  '0-10%',
];

export const TOOLTIP_BACKGROUND = 'Andel födda utanför Sverige i ett område';

export const BACKGROUND_COLORS = {
  colorScale: COLOR_SCALE_BG,
  colorScaleRgb: COLOR_SCALE_BG_RGB,
  colorLabels: LABELS_BACKGROUND,
  tooltip: TOOLTIP_BACKGROUND,
};
