import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { styles } from '../styles/componentStyles/LoadSelectedRegionButtonStyles';

class LoadSelectedRegionButton extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        id='loadRegionButtonGrid'
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        className={classes.loadSelectedRegionButtonGrid}
      >
        <Grid item xs={12} sm={5} md={4} lg={2} xl={2}>
          <Button
            className={classes.loadSelectedRegionButton}
            variant='contained'
            onClick={this.props.loadSelectedRegion}
          >
            {'Ladda aktuellt område'}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(LoadSelectedRegionButton);
