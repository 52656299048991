import React from 'react';
import { Card, CardContent, Hidden, Typography } from '@material-ui/core';

import Fade from '@mui/material/Fade';

import { samstatTheme } from '../../themes/SamstatTheme';

const Greeting = () => {
  return (
    <Fade appear={true} in={true} timeout={2000}>
      <Card
        style={{
          borderRadius: 0,
          boxShadow: 'none',
          marginTop: '50px',
          marginBottom: '50px',
        }}
      >
        <CardContent>
          <Typography
            variant='h2'
            style={{
              color: samstatTheme.palette.primary.main,
              fontFamily: samstatTheme.typography.h2,
              textAlign: 'center',
            }}
          >
            SAMSTAT
          </Typography>
          <br />
          <Hidden mdUp>
            <Typography
              variant='h6'
              style={{
                color: samstatTheme.palette.primary.main,
                fontFamily: samstatTheme.typography.h6,
                textAlign: 'center',
              }}
            >
              Visualiserad samhällsstatistik
            </Typography>
          </Hidden>
          <Hidden smDown>
            <Typography
              variant='h4'
              style={{
                color: samstatTheme.palette.primary.main,
                fontFamily: samstatTheme.typography.h4,
                textAlign: 'center',
              }}
            >
              Visualiserad samhällsstatistik
            </Typography>
          </Hidden>
        </CardContent>
      </Card>
    </Fade>
  );
};

export default Greeting;
