import React, { useEffect, useReducer } from 'react';
import DeckGL from 'deck.gl';
import { StaticMap } from 'react-map-gl';
// import { useDebounce } from 'use-debounce';

import {
  onClick,
  onViewStateChange,
  initialView,
  layersReducer,
  viewStateReducer,
} from '../../util/MapComponentUtil';

const SmallScreenMapComponent = ({
  deckGlConf,
  highlight_data,
  updateMetaData,
  updateCoord,
  nbrSearched,
}) => {
  const [layers, layersDispatch] = useReducer(layersReducer, []);
  const [mapView, mapViewDispatch] = useReducer(viewStateReducer, initialView);

  /*
  const [,] = useDebounce(
    () => {
      const consider = layers[0].props.visible; // a little bit sketchy, probably needs a better way
      console.log(consider);
      if (!consider) {
        showLayers();
        mapViewDispatch({ type: 'reset' });
      } else {
        mapViewDispatch({ type: 'reset' });
      }
    },
    500, // debounce wait
    [mapView] //dependencies that cause this function to re-run
  );
*/
  // this is maybe a little sketchy, find a good way to add initLayers
  // to the dependecy list of useEffect without causing infinite re-renders
  const initLayers = () =>
    layersDispatch({
      type: 'init',
      payload: {
        deckGlConf,
        highlight_data,
      },
    });

  const hideLayers = () =>
    layersDispatch({
      type: 'hide',
      payload: {
        deckGlConf,
        highlight_data,
      },
    });

  const _onClick = (info, _) =>
    onClick(info, deckGlConf.dataTypeGroup, updateMetaData);

  // const _onHover = (info) => onHover(info, setHoverInfo);

  // const _renderTooltip = () => renderTooltip();

  const _onViewStateChange = ({ viewState, interactionState }) =>
    onViewStateChange(
      interactionState,
      viewState,
      updateCoord,
      mapViewDispatch,
      true
    );

  useEffect(() => {
    if (
      deckGlConf.dataType !== '' &&
      deckGlConf.contentData !== null &&
      deckGlConf.regionData !== 'none' &&
      typeof layers !== undefined
    ) {
      if (mapView.isDragging || mapView.isZooming) {
        if (layers[0].props.visible === true) {
          // Inte super snyggt, fixar det sedan
          hideLayers();
        }
      } else {
        initLayers();
      }
    }
  }, [deckGlConf, mapView]);

  useEffect(() => {
    if (nbrSearched > 1) {
      initLayers();
    }
  }, [nbrSearched]);

  useEffect(() => {
    if (highlight_data !== null) {
      initLayers();
    }
  }, [highlight_data]);

  return (
    <div>
      <DeckGL
        onClick={_onClick}
        onViewStateChange={_onViewStateChange}
        initialViewState={{
          longitude: deckGlConf.coordinate.lng,
          latitude: deckGlConf.coordinate.lat,
          zoom: deckGlConf.coordinate.zoom,
          pitch: 0,
          bearing: 0,
        }}
        height={'100%'}
        width={'100%'}
        controller={true}
        layers={layers}
        _typedArrayManagerProps={{ overAlloc: 1, poolSize: 0 }}
      >
        <StaticMap mapStyle='https://api.maptiler.com/maps/c2e79edb-6ade-4864-89c5-b21a91cc9c55/style.json?key=R66sJIEjSIeJMOeJ8zUl' />
      </DeckGL>
    </div>
  );
};

export default SmallScreenMapComponent;
