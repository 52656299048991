import React from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';

import { styles } from '../../styles/componentStyles/AboutStyles';

const About = ({ closeAbout }) => {
  const classes = styles();
  return (
    <Grid
      id='navigationGrid'
      container
      item
      spacing={0}
      direction='column'
      xs={12}
      sm={5}
      md={4}
      lg={3}
      xl={2}
    >
      <Grid item>
        <Card
          style={{
            overflow: 'auto',
            width: 'calc(100vw - 20px)',
            maxHeight: 'calc(100vh - 20px)',
            zIndex: 9000,
            position: 'relative',
            margin: '10px',
            borderRadius: 0,
          }}
        >
          <CloseIcon
            className={classes.crossIcon}
            style={{
              right: '0px',
              position: 'absolute',
              padding: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              closeAbout();
            }}
          />
          <CardContent>
            <Typography variant='h3'>FAQ</Typography>
            <Typography variant='h5' color='textSecondary'>
              Vad är Samstat?
            </Typography>
            <Typography style={{ marginBottom: '10px' }}>
              Samstat är ett verktyg för visualisering av svensk
              samhällsstatistik. Sverige tillhandahåller en mängd öppna dataset
              och APIer, men för att ta del av informationen krävs ofta
              expertkunskap och specialiserade verktyg. Vi ser det som vårat
              uppdrag att göra den svenska offentliga datan tillgänglig till
              gemene man.
            </Typography>
            <Typography variant='h5' color='textSecondary'>
              Hur kan jag använda Samstat?
            </Typography>
            <Typography style={{ marginBottom: '10px' }} component='span'>
              <ol>
                <li>Skriv in gata/stadsdel/kommun av intresse i sökrutan.</li>
                <li>Välj datatyp av intresse.</li>
                <li>Klicka på sök för att ladda valt område och datatyp.</li>
                <li>Klicka på ett område för detaljerad information.</li>
              </ol>
            </Typography>
            <Typography variant='h5' color='textSecondary'>
              Var kommer datan ifrån?
            </Typography>
            <Typography style={{ marginBottom: '10px' }} component='span'>
              Vi använder data från olika källor. I samband med presentationen
              av datan anges alltid källan. Se nedan för en komplett lista med
              källor från datan vi använder:
              <ul>
                <li>
                  <a href='https://www.scb.se/vara-tjanster/oppna-data/oppna-geodata/'>
                    Statistiska centralbyrån
                  </a>
                </li>
                <li>
                  <a href='https://www.skolverket.se/om-oss/oppna-data'>
                    Skolverket
                  </a>
                </li>
              </ul>
            </Typography>
            <Typography style={{ marginBottom: '10px' }} component='span'>
              För frågor, problem eller kontakt vänligen hör av er på
              kontakt@gjertssonchen.se.
              <br />
              <br />
              Läs mer om våra Samstat och våra källor <Link to='/om'>här</Link>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default About;
